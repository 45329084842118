<template>
  <el-main>
    <el-row class="movie-list">
      <el-col :md="20" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的推荐</span>
          </div>
          <div>
            开启精选模式
            <el-switch
              v-model="modeEnabled"
              @change="onChange"
            >
            </el-switch>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { getUserRcmd, setUserRcmd } from '@/api/content'

export default {
  name: 'MyOAuth',
  data() {
    return {
      modeEnabled: false
    }
  },
  created() {
    document.title = '我的推荐'
    getUserRcmd().then(resp => {
      if (resp.code === 0) {
        this.modeEnabled = resp.data
      } else {
        this.$notify.warning({
          message: resp.msg,
          type: 'warning',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })
  },
  methods: {
    onChange(value) {
      this.modeEnabled = value
      const payload = {}
      payload.mode = this.modeEnabled
      setUserRcmd(payload).then(resp => {
        if (resp.code === 0) {
          var text = '精选模式已开启'
          if (!this.modeEnabled) {
            text = '精选模式已关闭'
          }
          this.$message.info(text)
        } else {
          this.$notify.warning({
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
